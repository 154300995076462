
import {
  MyPeralteesQuery,
  PeralteeSupportServicesSearchQuery,
  RequestConsultantPeralteeClaimMutation,
  RequestConsultantPeralteeClaimMutationMutation,
  RequestConsultantPeralteeClaimMutationMutationVariables,
} from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"

type ApplyPeralteeInput = {
  supportServiceId: number | null
  description: string
}

@Component
export default class ApplyPeralteeForm extends Vue {
  @Prop() readonly state!: string | null
  readonly supportServicesQuery = PeralteeSupportServicesSearchQuery
  isSaving = false

  defaultForm: ApplyPeralteeInput = {
    supportServiceId: null,
    description: "",
  }

  form: ApplyPeralteeInput = { ...this.defaultForm }

  resetForm() {
    this.form = { ...this.defaultForm }
    this.$emit("close")
    this.$refs.observer && (this.$refs.observer as any).reset()
  }

  async applyPeraltee() {
    const validity = await (this.$refs.observer as any).validateWithInfo()
    if (!validity.isValid) {
      return this.addError("Please fill all required fields")
    }
    this.isSaving = true
    const result = await this.$apollo.mutate<
      RequestConsultantPeralteeClaimMutationMutation,
      RequestConsultantPeralteeClaimMutationMutationVariables
    >({
      mutation: RequestConsultantPeralteeClaimMutation,
      variables: {
        supportServiceId: this.form.supportServiceId,
        description: this.form.description,
      },
      refetchQueries: [
        {
          query: MyPeralteesQuery,
          variables: {
            filter: {},
          },
        },
      ],
    })

    if (result.data && !result.data.requestConsultantPeralteeClaim.error) {
      this.addSuccess("Claim applied successfully!")
      this.resetForm()
    } else {
      this.resetForm()
    }

    this.isSaving = false
  }
}
